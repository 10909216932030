import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/RegisterPage.scss';
import AxiosInstance from '../AxiosInstance';

function RegisterPage() {
  const [formData, setFormData] = useState({
    nameSurname: '',
    email: '',
    phone: '',
    nationality: '',
    placeOfBirth: '',
    countryOfResidence: '',
    currentPosition: '',
    cv: null
  });
  const [message, setMessage] = useState('');
  const [formToggle, setFormToggle] = useState(false);
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };
  const handleFileChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      cv: e.target.files[0]
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    for (const key in formData) {
      if (typeof formData[key] === 'string' && formData[key].trim() === '') {
        setMessage('Please fill in all fields.');
        return;
      }
    }

    if (formToggle) {
      return;
    }


    const formDataToSend = new FormData();
    for (const key in formData) {
      formDataToSend.append(key, formData[key]);
    }

    try {
      const response = await AxiosInstance.post('/create-record', formDataToSend, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      console.log('response:', response.data);
      setMessage('Registration successful! Your application has been submitted. You are being redirected to the homepage...');
      setFormToggle(true);
      setTimeout(() => {
        window.location.href = 'https://www.kocaeli.edu.tr';
      }, 5000);
    } catch (error) {
      console.error('error:', error);
      setMessage('There was an error submitting your application. Please try again.');
    }
  };


  return (
    <div className="register-page container mt-5">
      <h1 className="register-page__title text-center mb-4">
        Application for Foreign Faculty Position for a Full-Time at Kocaeli University
      </h1>
      <div className="register-page__info mb-4">
        <p>
          Kocaeli University, as one of the qualified research and education institutions that have achieved significant success at the higher education level,
          invites qualified foreign researchers to be a part of our academic staff on a contractual basis.
          Researchers who want to make a preliminary application must fill out the form below.
          Applications of dual citizens with Turkish citizenship are not accepted.
          Those who want to apply as a research group can contact us via e-mail address.
        </p>
        <p><strong>Please feel free to contact us with any questions you may have!</strong></p>
        <p>Contact Email: <a href="mailto:arge@kocaeli.edu.tr">arge@kocaeli.edu.tr</a></p>
        <p>
          <a href="https://www.yok.gov.tr/Documents/Kurumsal/mevzuat/mevzuat_pdfler/Yuksekogretim_Kurumlarinda_Yabanci_Uyruklu_Ogretim_Gorevlilerin_Calistirilmasina_Iliskin_Karar.pdf" target='_blank'>
            Yükseköğretim Kurumlarında Yabancı Uyruklu Öğretim Elemanı Çalıştırılması Esaslarına İlişkin Bakanlar Kurulu Kararı
          </a>
        </p>
        <p> <a href="https://www.yok.gov.tr/Documents/Mevzuat/yabanci-uyruklu-ogretim-elemani-istihdami-usul-ve-esaslar.pdf" target='_blank'>
          YABANCI UYRUKLU ÖĞRETİM ELEMANI İSTİHDAMIYLA İLGİLİ USUL VE ESASLAR</a>
        </p>
      </div>
      <form onSubmit={handleSubmit} className="p-4 border rounded bg-light" enctype="multipart/form-data">
        <div className="mb-3">
          <label className="form-label">Name and Surname:</label>
          <input
            type="text"
            name="nameSurname"
            value={formData.nameSurname}
            onChange={handleChange}
            className="form-control"

          />
        </div>
        <div className="mb-3">
          <label className="form-label">E-mail Address:</label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className="form-control"
          />
        </div>
        <div className="mb-3">
          <label className="form-label">Phone Number:</label>
          <input
            type="tel"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            className="form-control"
          />
        </div>
        <div className="mb-3">
          <label className="form-label">Nationality:</label>
          <input
            type="text"
            name="nationality"
            value={formData.nationality}
            onChange={handleChange}
            className="form-control"
          />
        </div>
        <div className="mb-3">
          <label className="form-label">Place of Birth:</label>
          <input
            type="text"
            name="placeOfBirth"
            value={formData.placeOfBirth}
            onChange={handleChange}
            className="form-control"
          />
        </div>
        <div className="mb-3">
          <label className="form-label">Country of Residence:</label>
          <input
            type="text"
            name="countryOfResidence"
            value={formData.countryOfResidence}
            onChange={handleChange}
            className="form-control"
          />
        </div>
        <div className="mb-3">
          <label className="form-label">Current Position:</label>
          <input
            type="text"
            name="currentPosition"
            value={formData.currentPosition}
            onChange={handleChange}
            className="form-control"
          />
        </div>
        <div className="mb-3">
          <label className="form-label">Upload CV:</label>
          <input
            type="file"
            name="cv"
            accept="application/pdf"
            onChange={handleFileChange}
            className="form-control"
          />
          <small className="form-text text-muted">Please upload your CV in pdf format.</small>

        </div>
        {message && (
          <div className={`alert ${message.includes('successful') ? 'alert-success' : 'alert-danger'}`} role="alert">
            {message}
          </div>
        )}
        <div>
          <button type="submit" className="btn btn-primary w-100">Register</button>
        </div>
      </form>
    </div>
  );
}

export default RegisterPage;
