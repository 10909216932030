import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import RegisterPage from './pages/RegisterPage';
import AdminPage from './pages/AdminPage';
import LoginPage from './pages/LoginPage';
import PrivateRoute from './PrivateRoute';
function App() {
  return (
    <div >
      <Router>
        <Routes>
          <Route path='/' element={<RegisterPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path='/admin' element={
            <PrivateRoute>
              <AdminPage />
            </PrivateRoute>
          } />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
