import axios from 'axios';
const { apiUrl } = require('./config.json');

const axiosInstance = axios.create({
    baseURL: apiUrl, // Replace with your API base URL
    headers: { 'Content-Type': 'application/json' }
});

axiosInstance.interceptors.request.use(
    config => {
        const token = localStorage.getItem('token');
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

export default axiosInstance;