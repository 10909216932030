import React, { useEffect } from 'react'
import { Navigate, useNavigate } from 'react-router-dom';
import axiosInstance from './AxiosInstance';

function PrivateRoute({ children }) {
    const token = localStorage.getItem('token');
    const navigate = useNavigate();
    useEffect(() => {
        axiosInstance.get('/check-token').then((response) => {
            if (!response.data.message) {
                localStorage.removeItem('token');
                navigate('/');
            }
        }).catch((error) => {
            console.log(error);
        });
    }, [])

    return token ? children : <Navigate to='/' />
}

export default PrivateRoute