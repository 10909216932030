import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/AdminPage.scss';
import AxiosInstance from '../AxiosInstance';
const { apiUrl } = require('../config.json');

function AdminPage() {
  const [submissions, setSubmissions] = useState([]);
  const [filteredSubmissions, setFilteredSubmissions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const entriesPerPage = 15;

  const exampleData = [
    {
      nameSurname: 'John Doe',
      email: 'john.doe@example.com',
      phone: '+1234567890',
      nationality: 'American',
      placeOfBirth: 'New York City, NY',
      countryOfResidence: 'United States',
      currentPosition: 'Software Engineer',
      cv_file_name: 'john_doe_cv.pdf',
    },
    {
      nameSurname: 'Jane Smith',
      email: 'jane.smith@example.com',
      phone: '+9876543210',
      nationality: 'British',
      placeOfBirth: 'London, England',
      countryOfResidence: 'United Kingdom',
      currentPosition: 'Marketing Manager',
      cv_file_name: 'jane_smith_cv.pdf',
    },
    // Add more example entries here if needed.
  ];

  useEffect(() => {
    AxiosInstance.get('/records').then((response) => {
      setSubmissions(response.data);
      setFilteredSubmissions(response.data);
    }).catch((error) => {
      console.error('error:', error);
      setSubmissions(exampleData);
      setFilteredSubmissions(exampleData);
    });
  }, []);

  // Filter submissions based on the search term
  useEffect(() => {
    const filtered = submissions.filter(submission =>
      submission.nameSurname.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredSubmissions(filtered);
    setCurrentPage(1); // Reset to the first page when search term changes
  }, [searchTerm, submissions]);

  // Pagination logic
  const indexOfLastEntry = currentPage * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const currentEntries = filteredSubmissions.slice(indexOfFirstEntry, indexOfLastEntry);
  const totalPages = Math.ceil(filteredSubmissions.length / entriesPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div className="admin-page container mt-5">
      <h1 className="admin-page__title text-center mb-4">Submitted Applications</h1>

      {/* Search Bar */}
      <div className="search-bar mb-3">
        <input
          type="text"
          placeholder="Search by Name"
          className="form-control"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>

      <table className="table table-bordered">
        <thead>
          <tr>
            <th>Name and Surname</th>
            <th>Email</th>
            <th>Phone</th>
            <th>Nationality</th>
            <th>Place of Birth</th>
            <th>Country of Residence</th>
            <th>Current Position</th>
            <th>CV File</th>
          </tr>
        </thead>
        <tbody>
          {currentEntries.map((submission, index) => (
            <tr key={index}>
              <td data-label="Name and Surname">{submission.nameSurname}</td>
              <td data-label="Email">{submission.email}</td>
              <td data-label="Phone">{submission.phone}</td>
              <td data-label="Nationality">{submission.nationality}</td>
              <td data-label="Place of Birth">{submission.placeOfBirth}</td>
              <td data-label="Country of Residence">{submission.countryOfResidence}</td>
              <td data-label="Current Position">{submission.currentPosition}</td>
              <td data-label="CV File">
                <a href={`${apiUrl}/file/${submission.cv_file_name}`} target='_blank'>{submission.cv_file_name}</a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="pagination-controls text-center mt-3">
        <button onClick={handlePreviousPage} disabled={currentPage === 1} className="btn btn-secondary me-2">
          Previous
        </button>
        <span>Page {currentPage} of {totalPages}</span>
        <button onClick={handleNextPage} disabled={currentPage === totalPages} className="btn btn-secondary ms-2">
          Next
        </button>
      </div>
    </div>
  );
}

export default AdminPage;
